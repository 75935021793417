import React from "react"
import {Link} from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const QuizAuthPage = () => (
  <Layout>
    <SEO title="Quiz" />
    <main>
        <div className="standard-container">
            <h2 className="relative uk-text-center mt6 mb5">
                Forgot Password
                <div className="text-underline-heavy" style={{marginLeft: "auto", marginRight: "auto"}}></div>
            </h2>
            <div className="uk-margin">
              <div class="uk-margin-small">
                <form className="uk-form-horizontal uk-margin dark-form">
                    <div className="uk-margin">
                        <label className="uk-form-label" for="name">Email</label>

                        <div className="uk-form-controls">
                            <input className="uk-input" id="name" type="text" value="Your Registered Email ID"  />
                        </div>
                    </div>
                    <div className="uk-text-right">
                        <button class="uk-button uk-button-default uk-button-small">Request OTP</button>
                    </div>
                    <div className="uk-margin">
                        <label className="uk-form-label" for="otp">OTP</label>
                        <div className="uk-form-controls">
                            <input className="uk-input" name="otp" type="texg" value="Enter the Received OTP" />               
                        </div>
                    </div>
                    <div className="uk-margin uk-text-right">
                        <button className="uk-button uk-button-default" type="submit">Submit</button>
                    </div>
                </form>
                <div className="uk-text-center">
                    <Link class="uk-button uk-button-secondary uk-border-pill" type="button" to="/quiz/login-signup">
                                Back to Login
                    </Link>
                </div>
              </div>
            </div>
        </div>
    </main>
  </Layout>
)

export default QuizAuthPage
